import {Chord} from 'svguitar';

interface ChordDBFormat {
    frets: number[];
    fingers: number[];
    baseFret: number;
    barres: number[];
    midi?: number[];
}

const convertChordToSVGuitar = (chord: ChordDBFormat): Chord => {
    const stringCount = chord.frets.length;
    return {
        fingers: chord.frets.map((fret, index) => [
            stringCount - index,  // 1번 줄(가장 높은 음)이 마지막에 위치하도록 변환
            fret === -1 ? "x" : fret  // 뮤트(-1) 처리
        ]),
        barres: [],
        position: chord.baseFret,
    };
};

export const convertChordsToSVGuitar = (chords: ChordDBFormat[]): Chord[] => {
    if (!chords || chords.length === 0) {
        return [];
    }
    return chords.map(convertChordToSVGuitar);
};
