import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faUserAlt, faRunning, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from "../contexts/AuthContext";

const AuthButton: React.FC = () => {
    const { isAuthenticated, logout } = useAuth();

    const handleLogout = () => {
        const confirmLogout = window.confirm('로그아웃 하시겠습니까?');
        if (confirmLogout) {
            logout();
        }
    };

    return (
        <>
            {isAuthenticated ? (
                <Button
                    variant="outline-warning"
                    onClick={handleLogout}
                    className="nav-item btn-sm"
                >
                    <FontAwesomeIcon icon={faRunning} /> <FontAwesomeIcon icon={faSignOutAlt} />
                </Button>
            ) : (
                <Button
                    variant="outline-success"
                    as={Link as any}
                    to="/login"
                    className="nav-item btn-sm"
                >
                    <FontAwesomeIcon icon={faDoorOpen} /> <FontAwesomeIcon icon={faUserAlt} />
                </Button>
            )}
        </>
    );
};

export default AuthButton;
