import {InstrumentType} from "./InstrumentType";

export enum ChordExposureType {
    TEXT_GUITAR = "TEXT_GUITAR",
    TEXT_UKULELE = "TEXT_UKULELE",
    IMAGE_GUITAR = "IMAGE_GUITAR",
    IMAGE_UKULELE = "IMAGE_UKULELE"
}

export const ChordExposureTextType: ChordExposureType[] = [
    ChordExposureType.TEXT_GUITAR,
    ChordExposureType.TEXT_UKULELE
];

export const ChordExposureImageType: ChordExposureType[] = [
    ChordExposureType.IMAGE_GUITAR,
    ChordExposureType.IMAGE_UKULELE
];

export const DEFAULT_LOGGED_IN_CHORD_EXPOSURE_TYPE = ChordExposureType.TEXT_GUITAR;
export const DEFAULT_LOGGED_OUT_CHORD_EXPOSURE_TYPE = ChordExposureType.IMAGE_GUITAR;

// ChordExposureType - InstrumentType 매핑
export const ChordExposureToInstrumentMap: Record<ChordExposureType, InstrumentType> = {
    [ChordExposureType.TEXT_GUITAR]: InstrumentType.GUITAR,
    [ChordExposureType.IMAGE_GUITAR]: InstrumentType.GUITAR,
    [ChordExposureType.TEXT_UKULELE]: InstrumentType.UKULELE,
    [ChordExposureType.IMAGE_UKULELE]: InstrumentType.UKULELE
};
