import guitarChords from '../constants/chord-db/guitar.json';
import ukuleleChords from '../constants/chord-db/ukulele.json';
import { InstrumentType } from "../enums/InstrumentType";
import Chord from "@techies23/react-chords";

const keyAliases: { [instrument in InstrumentType]: { [key: string]: string } } = {
    [InstrumentType.GUITAR]: {
        'Cb': 'B',
        'Db': 'C#',
        'D#': 'Eb',
        'E#': 'F',
        'Fb': 'E',
        'Gb': 'F#',
        'G#': 'Ab',
        'A#': 'Bb',
        'B#': 'C'
    },
    [InstrumentType.UKULELE]: {
        'Cb': 'B',
        'C#': 'Db',
        'D#': 'Eb',
        'E#': 'F',
        'Fb': 'E',
        'F#': 'Gb',
        'G#': 'Ab',
        'A#': 'Bb',
        'B#': 'C'
    }
};

const formatKeyForJson = (key: string) => key?.replace('#', 'sharp');
const normalizeSuffix = (suffix: string) => {
    if (!suffix || suffix === 'M') return 'major';
    if (suffix.toLowerCase() === 'm') return 'minor';
    if (suffix.includes('M') || suffix.toLowerCase().includes('maj')) return suffix.replace('M', 'maj');
    suffix = suffix.replace(/-5/g, 'b5').replace(/\(b5\)/g, 'b5');
    return suffix;
};
const findChordsByKeyAndSuffix = (instrumentType: InstrumentType, key: string, suffix: string) => {
    const instrumentData = getInstrumentData(instrumentType);
    // @ts-ignore
    return instrumentData.chords[formatKeyForJson(keyAliases[instrumentType][key] || key)]?.filter(
        (chord: { suffix: string }) => chord.suffix === suffix
    );
};
const findChordsByText = (instrumentType: InstrumentType, chordText: string) => {
    const { key, suffix } = extractChordAndSuffix(chordText);
    const instrumentData = getInstrumentData(instrumentType);
    // @ts-ignore
    return instrumentData.chords[formatKeyForJson(keyAliases[instrumentType][key] || key)]?.filter(
        (chord: { suffix: string }) => chord.suffix === suffix
    );
};

export const extractChordAndSuffix = (chordText: string) => {
    const match = chordText.match(/^([A-Ga-g][#b]?)(.*)$/);
    if (match) {
        let key = match[1];
        let suffix = normalizeSuffix(match[2].trim());
        return { key, suffix };
    }
    return { key: '', suffix: '' };
};

export const getInstrumentData = (instrumentType: InstrumentType) => {
    return instrumentType === InstrumentType.GUITAR ? guitarChords : ukuleleChords;
};

export const renderChordDiagrams = (instrumentType: InstrumentType, key: string, suffix: string) => {
    const chordData = findChordsByKeyAndSuffix(instrumentType, key, suffix);
    if (!chordData || chordData.length === 0) return null;

    const instrumentData = getInstrumentData(instrumentType);
    const instrument = {
        strings: instrumentData.main.strings,
        fretsOnChord: instrumentData.main.fretsOnChord,
        name: instrumentData.main.name,
        tunings: instrumentData.tunings,
    };

    return chordData[0].positions
        .sort((a: { midi: number[], baseFret: number }, b: { midi: number[], baseFret: number }) => {
            // 1. baseFret 낮은 순서로 정렬
            if (a.baseFret !== b.baseFret) {
                return a.baseFret - b.baseFret;
            }
            // 2. 같은 baseFret이라면 midi 개수 많은 순서로 정렬
            return b.midi.length - a.midi.length;
        })
        .map((position: any, index: number) => (
            <div key={index} className="m-2" style={{ width: '130px', height: '170px' }}>
                <Chord chord={position} instrument={instrument} lite={false} />
            </div>
        ));
};

export const getChordPositions = (instrumentType: InstrumentType, chordText: string) => {
    const chordData = findChordsByText(instrumentType, chordText);
    if (!chordData || chordData.length === 0) return null;
    return chordData[0].positions
        .sort((a: { midi: number[], baseFret: number }, b: { midi: number[], baseFret: number }) => {
            // 1. baseFret 낮은 순서로 정렬
            if (a.baseFret !== b.baseFret) {
                return a.baseFret - b.baseFret;
            }
            // 2. 같은 baseFret이라면 midi 개수 많은 순서로 정렬
            return b.midi.length - a.midi.length;
        });
};