import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchChordSheet } from '../services/ChordSheetApiService';
import { ChordSheet } from '../models/ChordSheet';
import ChordSheetItem from "../components/ChordSheetItem";

const ChordSheetPage = () => {
    const { id } = useParams<{ id: string }>();
    const [chordSheet, setChordSheet] = useState<ChordSheet | null>(null);

    useEffect(() => {
        if (id) {
            fetchChordSheet(Number(id)).then(setChordSheet).catch(console.error);
        }
    }, [id]);

    return (
        <div>
            {chordSheet && (
                <Helmet>
                    <title>{`${chordSheet.title} - ${chordSheet.artist} | Bomione`}</title>

                    {/* ✅ Canonical URL */}
                    <link rel="canonical" href={`https://www.bomione.co.kr/chord-sheets/${id}`} />

                    {/* ✅ SEO */}
                    <meta
                        name="description"
                        content={`Guitar/Ukulele chord sheet for '${chordSheet.title}' by ${chordSheet.artist}. Transpose chords and change capo easily on Bomione.`}
                    />
                    <meta
                        name="keywords"
                        content={`봄이오네, Bomione, ${chordSheet.artist}, ${chordSheet.title}, 기타 코드, 우쿨렐레 코드, 기타 악보, 우쿨렐레 악보, 기타 코드악보, 우쿨렐레 코드악보, chord sheet, guitar chords, ukulele chords, KPop, Korean music, chord transposition, key change, capo`}
                    />

                    {/* ✅ Open Graph */}
                    <meta property="og:title" content={`${chordSheet.title} - ${chordSheet.artist} | Bomione`} />
                    <meta
                        property="og:description"
                        content={`Check out the chord sheet for '${chordSheet.title}' by ${chordSheet.artist}. Transpose chords and change capo position easily.`}
                    />
                    <meta property="og:url" content={`https://www.bomione.co.kr/chord-sheets/${id}`} />
                    <meta property="og:image" content="https://www.bomione.co.kr/logo/bomione_og.png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    {/* ✅ JSON-LD Structured Data */}
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "MusicComposition",
                            name: chordSheet.title,
                            composer: {
                                "@type": "Person",
                                name: chordSheet.artist,
                            },
                            inLanguage: "ko",
                            url: `https://www.bomione.co.kr/chord-sheets/${id}`,
                            image: "https://www.bomione.co.kr/logo/bomione_og.png",
                            description: `Guitar/Ukulele chord sheet for '${chordSheet.title}' by ${chordSheet.artist}. Transpose chords and change capo on Bomione.`,
                            keywords: `봄이오네, Bomione, ${chordSheet.artist}, ${chordSheet.title}, 기타 코드, 우쿨렐레 코드, 기타 악보, 우쿨렐레 악보, chord sheet, guitar chords, ukulele chords, KPop, Korean music, chord transposition, key change, capo`,
                            datePublished: chordSheet.createdDateTime.split('T')[0],
                            dateModified: chordSheet.lastModifiedDateTime.split('T')[0],
                            publisher: {
                                "@type": "Organization",
                                name: "Bomione",
                                url: "https://www.bomione.co.kr",
                                logo: {
                                    "@type": "ImageObject",
                                    url: "https://www.bomione.co.kr/logo/bomione_og.png"
                                }
                            }
                        })}
                    </script>
                </Helmet>
            )}
            <ChordSheetItem chordSheet={chordSheet} />
        </div>
    );
};

export default ChordSheetPage;
