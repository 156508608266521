import axios from 'axios';

let logoutHandler: (() => void) | null = null;

// 외부에서 등록할 수 있도록 export
export const setLogoutHandler = (handler: () => void) => {
    logoutHandler = handler;
};

const protocol = window.location.protocol;

const api = axios.create({
    baseURL: `${protocol}//${process.env.REACT_APP_BE_API_DOMAIN}/api`,
});

// 요청 시 JWT 토큰 자동 추가
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// 응답에서 401 에러 발생 시 logoutHandler 실행
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401 && logoutHandler) {
            // JWT Token 만료 또는 유효하지 않은 경우
            logoutHandler(); // 🔥 등록된 로그아웃 핸들러 실행
        }
        return Promise.reject(error);
    }
);

export default api;
