import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { InstrumentType } from '../enums/InstrumentType';
import { getInstrumentData, extractChordAndSuffix, renderChordDiagrams } from '../utils/chordDBUtils';
import GoogleAd from "./GoogleAd";

interface Props {
    initialInstrumentType?: InstrumentType;
    defaultKey?: string;
    defaultSuffix?: string;
}

const ChordDB: React.FC<Props> = ({
                                             initialInstrumentType = InstrumentType.GUITAR,
                                             defaultKey = 'C',
                                             defaultSuffix = 'major'
                                         }) => {
    const [instrumentType, setInstrumentType] = useState<InstrumentType>(initialInstrumentType);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedKey, setSelectedKey] = useState<string>(defaultKey);
    const [selectedSuffix, setSelectedSuffix] = useState<string>(defaultSuffix);

    const instrumentData = getInstrumentData(instrumentType);
    const keys = ['', 'Cb', 'C', 'C#', 'Db', 'D', 'D#', 'Eb', 'E', 'E#', 'Fb', 'F', 'F#', 'Gb', 'G', 'G#', 'Ab', 'A', 'A#', 'Bb', 'B', 'B#'];
    const suffixes = ['', ...instrumentData.suffixes];

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let filteredValue = e.target.value.replace(/[^A-Za-z0-9#\/]/g, '');
        filteredValue = filteredValue.length > 0 ? filteredValue.charAt(0).toUpperCase() + filteredValue.slice(1) : '';

        setSearchText(filteredValue);
        const { key, suffix } = extractChordAndSuffix(filteredValue);
        setSelectedKey(keys.includes(key) ? key : '');
        setSelectedSuffix(suffixes.includes(suffix) ? suffix : '');
    };

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedKey(e.target.value);
        setSearchText('');
    };

    const handleSuffixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSuffix(e.target.value);
        setSearchText('');
    };

    const handleInstrumentTypesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInstrumentType(e.target.value as InstrumentType);
        setSearchText('');
        setSelectedKey('C');
        setSelectedSuffix('major');
    };

    return (
        <Container style={{ paddingTop: '20px' }}>
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group controlId="search-term">
                        <Form.Control
                            type="text"
                            placeholder="Search Chords"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={6}>
                    <Form.Group controlId="key-select">
                        <Form.Control as="select" value={selectedKey} onChange={handleKeyChange}>
                            {keys.map(key => (
                                <option key={key} value={key}>{key}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group controlId="suffix-select">
                        <Form.Control as="select" value={selectedSuffix} onChange={handleSuffixChange}>
                            {suffixes.map(suffix => (
                                <option key={suffix} value={suffix}>{suffix}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group controlId="instrument-select">
                        <Form.Control
                            as="select"
                            value={instrumentType}
                            onChange={handleInstrumentTypesChange}
                        >
                            <option value={InstrumentType.GUITAR}>Guitar</option>
                            <option value={InstrumentType.UKULELE}>Ukulele</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="d-flex flex-wrap justify-content-center">
                    {renderChordDiagrams(instrumentType, `${selectedKey}`, `${selectedSuffix}`) || <p>선택된 코드가 없습니다.</p>}
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <div style={{ minHeight: '280px' }}>
                        {selectedKey && selectedSuffix && (
                            <GoogleAd
                                adClient="ca-pub-6083003342312279"
                                adSlot="9212812474" // 👉 실제 애드센스 슬롯 ID로 교체 필요 (완료)
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ChordDB;
