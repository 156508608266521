import {Chord, ChordStyle, Orientation, SVGuitarChord} from 'svguitar';
import {ChordExposureImageType, ChordExposureToInstrumentMap, ChordExposureType} from "../enums/ChordExposureType";
import {getChordPositions} from "./chordDBUtils";
import {convertChordsToSVGuitar} from "./chordFormatConverters";

const configureMap = {
    [ChordExposureType.IMAGE_GUITAR]: {
        titleFontSize: 120,
        fretSize: 1,
        fingerSize: 1,
        strings: 6,
        frets: 5
    },
    [ChordExposureType.IMAGE_UKULELE]: {
        titleFontSize: 120,
        fretSize: 0.7,
        fingerSize: 0.6,
        strings: 4,
        frets: 4,
    },
    [ChordExposureType.TEXT_GUITAR]: {},
    [ChordExposureType.TEXT_UKULELE]: {},
};

configureMap[ChordExposureType.TEXT_GUITAR] = configureMap[ChordExposureType.IMAGE_GUITAR];
configureMap[ChordExposureType.TEXT_UKULELE] = configureMap[ChordExposureType.IMAGE_UKULELE];

export const getChordSvg = (chordExposureType: ChordExposureType, chordName: string, index: number = 0): Chord | null => {
    // ChordDB에서 해당 코드의 포지션을 가져와 SVGuitar 형식으로 변환
    const instrumentType = ChordExposureToInstrumentMap[chordExposureType];
    const chordDBPositions = getChordPositions(instrumentType, chordName);
    const chords = convertChordsToSVGuitar(chordDBPositions);
    if (!chords || chords.length === 0) {
        return null; // 해당 코드가 없거나 포지션이 없는 경우 null 반환
    }
    return chords[index] || null; // 인덱스가 범위를 벗어나면 null 반환
};

export const renderChordSvg = (span: HTMLSpanElement, chordExposureType: ChordExposureType, chordName: string) => {
    span.innerHTML = '';
    const chordSvg = getChordSvg(chordExposureType, chordName);
    if (!chordSvg) {
        return
    }
    const chord = new SVGuitarChord(span)
        .configure({
            ...(ChordExposureImageType.includes(chordExposureType) ? { title: chordName } : {}), // 이미지 모드일 때만 title 추가
            style: ChordStyle.normal,
            orientation: Orientation.horizontal,
            ...configureMap[chordExposureType],
        })
        .chord(chordSvg);
    chord.draw();
};
