import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { UserInfoResponse } from '../models/UserInfoResponse';
import { fetchCurrentUserInfo } from '../services/UserApiService';
import { setLogoutHandler } from '../services/api'; // 🔥 api.ts에서 import

interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    userInfo: UserInfoResponse | null;
    setUserInfo: (user: UserInfoResponse | null) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));
    const [userInfo, setUserInfo] = useState<UserInfoResponse | null>(null);

    // 🔐 로그아웃 로직
    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserInfo(null);
        window.location.href = '/login';
    };

    // 🔥 api.ts에 logoutHandler 등록
    useEffect(() => {
        setLogoutHandler(logout);
    }, []);

    // 🔍 최초 실행 시 사용자 정보 로드
    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsAuthenticated(!!token);

        if (token) {
            fetchCurrentUserInfo()
                .then(userInfo => {
                    //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
                    setUserInfo(userInfo);
                })
                .catch(() => setUserInfo(null));
        } else {
            setUserInfo(null);
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, setIsAuthenticated, userInfo, setUserInfo, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
