import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faExpand, faCompress} from "@fortawesome/free-solid-svg-icons";

export enum TextDisplayType {
    EXPANDED = "EXPANDED",
    COMPRESSED = "COMPRESSED"
}

export const TEXT_DISPLAY_ICONS: { [key in TextDisplayType]: IconDefinition } = {
    [TextDisplayType.EXPANDED]: faExpand,
    [TextDisplayType.COMPRESSED]: faCompress,
};

export const DEFAULT_TEXT_DISPLAY_TYPE = TextDisplayType.EXPANDED;