import {TransposeType} from "../enums/TransposeType";

const keys = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

const enHarmonics: { [key: string]: string } = {
    "Cb": "B",
    "Db": "C#",
    "Eb": "D#",
    "Fb": "E",
    "Gb": "F#",
    "Ab": "G#",
    "Bb": "A#",
    "B#": "C",
    "E#": "F"
};

const normalizeKey = (key: string): string => {
    return enHarmonics[key] || key;
}

export const getKeyDifferenceText = (originalKey: string, targetKey: string): string => {
    const normalizedOriginalKey = normalizeKey(originalKey);
    const normalizedTargetKey = normalizeKey(targetKey);

    const originalIndex = keys.indexOf(normalizedOriginalKey);
    const targetIndex = keys.indexOf(normalizedTargetKey);

    if (originalIndex === -1 || targetIndex === -1) {
        throw new Error(`Invalid key provided: ${normalizedOriginalKey}, ${normalizedTargetKey}`);
    }

    const baseText = `【${normalizedTargetKey}】【원키`;
    // 12음계 순환을 고려한 차이 계산
    let difference = (originalIndex - targetIndex + 12) % 12;
    // 반대 방향이 더 짧다면 변환 (7 이상이면 -값으로 조정)
    if (difference > 6) difference -= 12;
    // 무조건 부호 반전하여 +는 낮은 키에서 높은 키로 이동하는 의미로 변환
    difference = -difference;

    return difference === 0 ? `${baseText}】` : `${baseText} ${difference > 0 ? `+${difference}` : `${difference}`}】`;
};

export const getKeyWithCapoText = (targetKey: string, capo: number): string => {
    const normalizedTargetKey = normalizeKey(targetKey);
    const targetIndex = keys.indexOf(normalizedTargetKey);
    const applyCapoIndex = (targetIndex + capo) % keys.length;
    return `【${keys[applyCapoIndex]}】`;
};

export const getCapoByTranspose = (capo: number, transposeType: TransposeType): number => {
    return transposeType === TransposeType.UP ? (capo + 1) % 12 : (capo - 1 + 12) % 12;
}
