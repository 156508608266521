import { useCallback, useEffect, useState, useRef } from "react";

export const useAutoScrollController = () => {
    const defaultSpeedLevels = [0, 0.5, 1];
    const adjustedSpeedLevels = [0, 1, 2]; // 일부 환경에서 0.5 무시될 경우 보정
    const speedLevelsRef = useRef([...defaultSpeedLevels]); // 🔥 동적으로 변경될 수 있도록 useRef 사용
    const [speedIndex, setSpeedIndex] = useState(0);
    let scrollAnimationFrame: number | null = null;

    /** 🔥 기존 애니메이션 프레임 해제 */
    const clearScrollAnimationFrame = () => {
        if (scrollAnimationFrame !== null) {
            cancelAnimationFrame(scrollAnimationFrame);
            scrollAnimationFrame = null;
        }
    };

    /** 🔥 스크롤 정지 */
    const stopScrolling = () => {
        setSpeedIndex(0);
        clearScrollAnimationFrame();
        speedLevelsRef.current = [...defaultSpeedLevels]; // 🔥 보정된 값 적용 후 다시 원래 값으로 리셋
    };

    /** 🔥 자동 스크롤 실행 */
    const handleAutoScroll = useCallback(() => {
        setSpeedIndex(prevIndex => {
            const newSpeedIndex = (prevIndex + 1) % speedLevelsRef.current.length;
            clearScrollAnimationFrame();

            if (newSpeedIndex > 0) {
                const scrollSmoothly = (currentSpeedIndex: number) => {
                    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                        stopScrolling();
                        return;
                    }

                    let scrollValue = speedLevelsRef.current[currentSpeedIndex];
                    const initialScrollY = window.scrollY;
                    const targetScrollY = initialScrollY + scrollValue;

                    // 🔥 `behavior: "instant"`을 사용하여 강제 이동 (일부 환경에서 scrollTo 적용 보장)
                    // @ts-ignore
                    window.scrollTo({ top: targetScrollY, behavior: "instant" });

                    // 🔥 50ms 뒤에 스크롤이 실제로 변경되었는지 확인 후 보정
                    setTimeout(() => {
                        if (window.scrollY === initialScrollY) {
                            console.warn(`⚠️ Scroll ignored, adjusting speed to integer values`);
                            speedLevelsRef.current = [...adjustedSpeedLevels]; // 🔥 속도 보정
                            scrollValue = speedLevelsRef.current[currentSpeedIndex];
                            // @ts-ignore
                            window.scrollTo({ top: initialScrollY + scrollValue, behavior: "instant" });
                        }
                    }, 50);

                    scrollAnimationFrame = requestAnimationFrame(() => scrollSmoothly(currentSpeedIndex));
                };

                scrollAnimationFrame = requestAnimationFrame(() => scrollSmoothly(newSpeedIndex));
            } else {
                stopScrolling();
            }

            return newSpeedIndex;
        });
    }, []);

    /** 🔥 버튼 외부 클릭 시 자동 스크롤 정지 */
    useEffect(() => {
        const handleStopScrolling = (event: MouseEvent | TouchEvent) => {
            const button = document.querySelector(".auto-scroll-button");
            if (button && button.contains(event.target as Node)) {
                return;
            }
            stopScrolling();
        };

        document.addEventListener("touchstart", handleStopScrolling);
        document.addEventListener("mousedown", handleStopScrolling);

        return () => {
            document.removeEventListener("touchstart", handleStopScrolling);
            document.removeEventListener("mousedown", handleStopScrolling);
        };
    }, []);

    return { speedIndex, handleAutoScroll };
};
