import React from 'react';
import { Helmet } from 'react-helmet';
import ChordDB from '../components/ChordDB';

const ChordDBPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Chord Finder | Bomione</title>

                {/* ✅ Canonical URL */}
                <link rel="canonical" href={`https://www.bomione.co.kr/chords`} />

                {/* ✅ SEO */}
                <meta
                    name="description"
                    content="Find almost any guitar or ukulele chord instantly. Easily search chords by text and view them with clear, position-based diagrams."
                />
                <meta
                    name="keywords"
                    content="Bomione, chord finder, guitar chords, ukulele chords, chord chart, chord diagram, chord shapes, chord viewer, chord database, chords-db"
                />

                {/* ✅ Open Graph */}
                <meta property="og:title" content="Chord Finder | Bomione" />
                <meta
                    property="og:description"
                    content="Search and view nearly all guitar and ukulele chords with easy-to-read diagrams. Fast text search included."
                />
                <meta property="og:url" content="https://www.bomione.co.kr/chords" />
                <meta property="og:image" content="https://www.bomione.co.kr/logo/bomione_og.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                {/* ✅ JSON-LD Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        name: "Chord Finder | Bomione",
                        url: "https://www.bomione.co.kr/chords",
                        description:
                            "Search and display almost every guitar and ukulele chord with clear, position-based diagrams and fast text search support.",
                        image: "https://www.bomione.co.kr/logo/bomione_og.png",
                        publisher: {
                            "@type": "Organization",
                            name: "Bomione",
                            url: "https://www.bomione.co.kr",
                            logo: {
                                "@type": "ImageObject",
                                url: "https://www.bomione.co.kr/logo/bomione_og.png"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <ChordDB />
        </>
    );
};

export default ChordDBPage;
