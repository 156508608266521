import ChordSheetJS, { Song, Chord } from "chordsheetjs";
import { ChordExposureType, ChordExposureTextType } from "../enums/ChordExposureType";
import { TextDisplayType } from "../enums/TextDisplayType";
import { getChordSvg, renderChordSvg } from "./chordSvgUtils";

const getChordProParser = () => new ChordSheetJS.ChordProParser();

const isEnharmonic = (chordText: string): boolean =>
    ['A#', 'D#', 'Gb', 'Ab', 'Db'].some(note => chordText.includes(note));

const normalizeChord = (chordText: string, key: string): string | null =>
    Chord.parse(chordText)?.normalize(key)?.toString() || null;

const replaceChordText = (chordText: string): string =>
    chordText.replace(/ma(j)?/, 'M');

const createSpanElement = (): HTMLSpanElement => {
    return document.createElement('span');
};

const buildChordText = (mainChordText: string, bassChordText: string): string =>
    `${mainChordText}${bassChordText ? '<span class="bass-chord-text">/' + bassChordText + '</span>' : ''}`

const getProcessedChordSheetText = (chordSheetText: string, textDisplayType: TextDisplayType): string => {
    // [#9] AmM7, add9 등 일부 Chord 가 변환이 안되는 이슈가 있어서 임시로 처리 (https://github.com/soondor81/bomione/issues/9)
    chordSheetText = chordSheetText
        .replace(/mM7/gi, "m7") // "mM7", "Mm7", "MM7" 등
        .replace(/add9/gi, "") // "add9", "ADD9" 등
        .replace(/aug(?=[\]\/])/gi, "7"); // "[Gaug]", "[Gaug/B]" 등 (가사의 aug 가 변환되지 않도록 postfix 지정)
    // 9, 11, 13도 Chord 는 7도로 변환
    /*chordSheetText = chordSheetText
        .replace(/9(?=[\]\/\sus])/gi, "7")
        .replace(/11(?=[\]\/\sus])/gi, "7")
        .replace(/13(?=[\]\/\sus])/gi, "7");*/
    return textDisplayType === TextDisplayType.COMPRESSED
        ? chordSheetText.replace(/\s+/g, " ") // "\n(개행) 및 "  "(공백2개) 를 " "(공백1개)로 변경
        : chordSheetText;
};

export const parseChordSheetText = (chordSheetText: string): Song =>
    getChordProParser().parse(chordSheetText);

export const toText = (song: Song): string => {
    const formatter = new ChordSheetJS.TextFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toChordPro = (song: Song): string => {
    const formatter = new ChordSheetJS.ChordProFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toHtml = (song: Song): string => {
    const formatter = new ChordSheetJS.HtmlDivFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toChordHtml = (song: Song, chordExposureType: ChordExposureType): string => {
    const htmlText = toHtml(song);
    //console.log(`htmlText: ${htmlText}`);
    const doc = new DOMParser().parseFromString(htmlText, 'text/html');

    doc.querySelectorAll('.lyrics').forEach((lyricsElement) => {
        lyricsElement.innerHTML = lyricsElement.innerHTML.replace(/\s/g, '&nbsp;');
    });

    doc.querySelectorAll('.chord').forEach((chordElement: Element) => {
        let [mainChordText, bassChordText] = chordElement.textContent ? chordElement.textContent.split('/') : ["", ""];

        if (mainChordText) {
            if (isEnharmonic(mainChordText)) {
                mainChordText = normalizeChord(mainChordText, 'F#m')!;
            }
            mainChordText = replaceChordText(mainChordText);
            (chordElement as HTMLElement).setAttribute('data-main-chord', mainChordText);
        }

        if (ChordExposureTextType.includes(chordExposureType)) {
            chordElement.innerHTML = buildChordText(mainChordText, bassChordText);
        } else {
            const span = createSpanElement();
            if (mainChordText) {
                const chordSvg = getChordSvg(chordExposureType, mainChordText);
                if (chordSvg) {
                    span.className = 'image-chord';
                    renderChordSvg(span, chordExposureType, mainChordText);
                } else {
                    span.className = 'image-chord--text';
                    span.innerHTML = buildChordText(mainChordText, bassChordText);
                }
            } else {
                span.className = 'image-chord--empty';
            }
            chordElement.parentNode?.replaceChild(span, chordElement);
        }
    });

    return doc.documentElement.outerHTML;
};

export const transposeKey = (song: Song, key: string): Song => {
    const isFlat = key.endsWith('b');
    let transposeDistance = song.getTransposeDistance(key);
    if (isFlat) {
        transposeDistance -= 12;
    }
    return song.transpose(transposeDistance);
};

export const createParsedSong = (chordSheetText: string, textDisplayType: TextDisplayType, key: string): Song => {
    let processedText = getProcessedChordSheetText(chordSheetText, textDisplayType);
    let parsedSong = parseChordSheetText(processedText);
    parsedSong = transposeKey(parsedSong, key);
    return parsedSong;
};