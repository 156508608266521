import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface Props {
    children: React.ReactNode;
}

const RequirePremium: React.FC<Props> = ({ children }) => {
    const { isAuthenticated, userInfo } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (!userInfo?.isPremium) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default RequirePremium;