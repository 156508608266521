import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequirePremium from './components/RequirePremium';
import ChordSheetListPage from "./pages/ChordSheetListPage";
import ChordSheetListTestPage from "./pages/test/ChordSheetListTestPage";
import FavoriteChordSheetListPage from "./pages/FavoriteChordSheetListPage";
import ChordDBPage from "./pages/ChordDBPage";
import PremiumChordDBPage from "./pages/test/ChordDBTestPage";
import ChordSheetPage from "./pages/ChordSheetPage";
import ChordSheetTestPage from "./pages/test/ChordSheetTestPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NoticePage from "./pages/NoticePage";
import RegisterPage from "./pages/RegisterPage";
import NavigationBar from './components/NavigationBar';
import { AuthProvider } from './contexts/AuthContext';
import './assets/styles/App.css';

function App() {
    return (
        <AuthProvider>
            <Router>
                <NavigationBar />
                <div className="custom-container">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/chord-sheets/favorites" element={<FavoriteChordSheetListPage />} />
                        <Route path="/chord-sheets/:id" element={<ChordSheetPage />} />
                        <Route path="/chord-sheets" element={<ChordSheetListPage />} />
                        <Route path="/chords" element={<ChordDBPage />} />
                        <Route path="/notices" element={<NoticePage />} />

                        {/* Test pages start */}
                        <Route path="/test/chord-sheets/:id" element={<ChordSheetTestPage />} />
                        <Route path="/test/chord-sheets" element={<ChordSheetListTestPage />} />
                        <Route
                            path="/test/chords"
                            element={
                                <RequirePremium>
                                    <PremiumChordDBPage />
                                </RequirePremium>
                            }
                        />
                        {/* Test pages end */}
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
