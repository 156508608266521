import React, { useEffect, useState } from "react";
import "../assets/styles/AutoScrollButton.css";

const AutoScrollButton: React.FC<{ onClick: () => void; speed: number }> = ({ onClick, speed }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = window.innerHeight;
            const scrollTop = window.scrollY;
            const isScrollable = scrollHeight - clientHeight > 1 && scrollTop + clientHeight < scrollHeight;
            setIsVisible(isScrollable);
        };

        setTimeout(checkScroll, 200); // DOM 렌더링 후 200ms 뒤 체크

        window.addEventListener("scroll", checkScroll);
        window.addEventListener("resize", checkScroll);

        return () => {
            window.removeEventListener("scroll", checkScroll);
            window.removeEventListener("resize", checkScroll);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <button className="auto-scroll-button" onClick={onClick}>
            {speed > 0 ? `${speed}x` : ""}
        </button>
    );
};

export default AutoScrollButton;
