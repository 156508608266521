import React, { useEffect } from 'react';

interface GoogleAdProps {
    adClient: string;
    adSlot: string;
    style?: React.CSSProperties;
    format?: string;
    responsive?: boolean;
}

const GoogleAd: React.FC<GoogleAdProps> = ({
   adClient,
   adSlot,
   style = { display: 'block' },
   format = 'auto',
   responsive = true
}) => {
    useEffect(() => {
        try {
            // @ts-ignore
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.error('Adsense error', e);
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={style}
            data-ad-client={adClient}
            data-ad-slot={adSlot}
            data-ad-format={format}
            data-full-width-responsive={responsive ? 'true' : 'false'}
        ></ins>
    );
};

export default GoogleAd;
