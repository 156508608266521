import React from "react";
import ChordSheetItems from "../../components/ChordSheetItems";
import GoogleAd from "../../components/GoogleAd";

const ChordSheetListTestPage = () => {
    return (
        <div>
            <p style={{textAlign: 'center', color: 'gray', fontSize: '0.9rem'}}>
                This page is for advertisement testing purposes only.<br/>
                Please visit the actual <a href="/chord-sheets" style={{color: '#007bff', textDecoration: 'underline'}}>
                Chord Sheets</a> page for real content.
            </p>
            <ChordSheetItems/>
            {/*<GoogleAd*/}
            {/*    adClient="ca-pub-6083003342312279"*/}
            {/*    adSlot="9212812474" // 👉 실제 애드센스 슬롯 ID로 교체 필요 (완료)*/}
            {/*/>*/}
            <GoogleAd
                adClient="ca-pub-3940256099942544"   // ✅ 테스트용 client ID
                adSlot="1234567890"                  // ✅ 아무 숫자도 괜찮음
            />
        </div>
    );
};

export default ChordSheetListTestPage;